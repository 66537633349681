.modal-backdrop {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgb(14, 12, 26,0.5);
    z-index: 1;
    
  }
  
  .modal-content-wrapper {
    position: fixed;
    width: 30vw;
    height: 80vh;
    background: #131024;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border: 1px rgb(0, 136, 255 , 0.5) solid;
  }

  @media only screen and (max-width: 600px) {
    .modal-content-wrapper {
      position: fixed;
      width: 75vw;
      height: 70vh;
      background: #131024;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      border: 1px rgb(0, 136, 255 , 0.5) solid;
    }
  }
 
  @media only screen and  (min-width: 660px) and (max-width: 768px) {
    .modal-content-wrapper {
      position: fixed;
      width: 70vw;
      height: 70vh;
      background: #131024;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      border: 1px rgb(0, 136, 255 , 0.5) solid;
    }
  }

  @media only screen and  (min-width: 768px) and (max-width: 992px) {
    .modal-content-wrapper {
      position: fixed;
      width: 50vw;
      height: 75vh;
      background: #131024;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      border: 1px rgb(0, 136, 255 , 0.5) solid;
    }
  } 

  @media only screen and (min-width: 992px) and  (max-width: 1200px) {
    .modal-content-wrapper {
      position: fixed;
      width: 50vw;
      height: 75vh;
      background: #131024;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      border: 1px rgb(0, 136, 255 , 0.5) solid;
    }
  } 