.videos {
    border:1px solid #1473FB;
      border-radius: 5px;
      box-shadow: 10px 10px 5px #0E122C;
}

@media screen and (max-width: 39.9375em) {
    .videos {
      
          width: 100%;
         
    }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {

    .videos {
      
        width: 50%;
       
  }
}

@media screen and (min-width: 64em) {

    .videos {
      
        width: 60%;
       
  }
}

@-moz-document url-prefix() {

    
    .videos {
      
        width: 90%;
       
  }


  @media screen and (max-width: 39.9375em) {
    .videos {
      
          width: 100%;
         
    }
}
}