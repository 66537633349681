body::-webkit-scrollbar {
    width: 18px;               /* width of the entire scrollbar */
  }
  
  body::-webkit-scrollbar-track {
    background: #0E0C1A;        /* color of the tracking area */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);

  }


html {
    background-color: #0E0C1A;
    scrollbar-color: rgba(255, 255, 255, 0.2) #0E0C1A;
}

*:focus {
  box-shadow: none !important;
}

a:hover{
  text-decoration: none!important;
}